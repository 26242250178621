import React, { useState } from 'react';
import Editor from '../editor';

import { MDBCol, MDBRow } from 'mdbreact';

const EditorInput = ({
  name,
  label,
  required,
  className,
}) => {
  const [value, setValue] = useState('');

  return (
    <MDBRow className={className}>
      <MDBCol>
        {
          label
            ? (
              <label>
                {label}
                {
                  required
                    ? <span className="requiredField">*</span>
                    : <></>
                }
              </label>
            )
            : <></>
        }
        <Editor
          onChange={value => setValue(value)}
          data={value}
        />
        <input name={name} value={value} style={{ display: 'none' }} />
      </MDBCol>
    </MDBRow>
  );
};

export default EditorInput;
